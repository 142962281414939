import React from 'react';
import {useSettings, useStyles} from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import {Text, TextPriority, IconButton, Divider} from 'wix-ui-tpa/cssVars';
import {Close} from '@wix/wix-ui-icons-common/on-stage';
import {classes} from './Header.st.css';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import {useTranslation} from '@wix/yoshi-flow-editor';
import stylesParams from '../../../stylesParams';

export enum HeaderDataHooks {
  title = 'Header.Title',
  itemsCountText = 'Header.itemsCountText',
  closeButton = 'Header.closeButton',
}

export interface HeaderProps {
  closeButtonRef: React.RefObject<HTMLButtonElement>;
}

export const Header = ({closeButtonRef}: HeaderProps) => {
  const {get: getSettings} = useSettings();
  const {get: getStyles} = useStyles();
  const {itemsCount} = useControllerProps().cartStore;
  const {closeSideCart} = useControllerProps().navigationStore;
  const {t} = useTranslation();
  const showTitle = getStyles(stylesParams.CART_TITLE_VISIBILITY);
  const showItemsCount = getStyles(stylesParams.CART_NUMBER_OF_CART_ITEMS_VISIBILITY);

  return (
    <div className={classes.headerContainer}>
      <div className={classes.header}>
        <Text priority={TextPriority.primary} className={classes.headerText}>
          {showTitle && (
            <>
              <Text tagName={'h2'} data-hook={HeaderDataHooks.title} className={classes.headerTitle}>
                {getSettings(settingsParams.CART_TITLE) as string}
              </Text>{' '}
            </>
          )}
          {showItemsCount && (
            <Text data-hook={HeaderDataHooks.itemsCountText} className={classes.headerItemCount}>
              {t('sideCart.title.itemQuantity', {itemQuantity: itemsCount ?? 0})}
            </Text>
          )}
        </Text>
        <IconButton
          innerRef={closeButtonRef}
          className={classes.closeButton}
          data-hook={HeaderDataHooks.closeButton}
          icon={<Close className={classes.closeButtonIcon} />}
          aria-label={t('sideCart.closeCart.ariaLabel')}
          onClick={() => closeSideCart()}
        />
      </div>
      <Divider className={classes.divider} />
    </div>
  );
};
